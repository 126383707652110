/* eslint no-param-reassign: ["error", { "props": false }] */

/// //////////////// NOT IN USE , JUST A TEMPLATE //////////////

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './index'

const SLICE_NAME = 'user'
export interface UserState {
  user: number
}

export const initialState: UserState = {
  user: 0,
}

export const userApi = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<number>) => {
      state.user = action.payload + 1
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateUser } = userApi.actions

export const selectUser = (state: RootState) => state.user

export default userApi.reducer
