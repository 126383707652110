import { isEmpty } from 'lodash-es'

const keyName = 'HP_utm_data'

export const setUtmSessionData = () => {
  const data = new URLSearchParams(window.location.search)

  const dataObj: { [key: string]: string | undefined } = {}

  data.forEach((value, key) => {
    dataObj[key] = value || undefined
  })

  if (isEmpty(dataObj)) return

  sessionStorage.setItem(keyName, JSON.stringify(dataObj))
}

export const getUtmSessionData = () => {
  const key = sessionStorage.getItem(keyName)

  return key ? JSON.parse(key) : null
}
