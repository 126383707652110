import Cookies from 'js-cookie'
import { Item } from './interfaces'
import { getUtmSessionData } from '../data/utmData'

export const countItems = (items: []) => {
  /*
     state_flow oreder:
     submission -> validation_needed -> estimation_bid -> estimation_needed ->
     estimation_ready -> shipping -> delivered -> pending_sale -> pending_auction ->
     evaluation_bid -> bidding_finished -> evaluation_ready -> accepted ->
     loan_active -> return_pending -> on_hold -> pre_inventory -> inventory ->
     sale_scheduled -> done
  */

  const allowedStates = ['validation_needed', 'estimation_bid', 'estimation_needed', 'estimation_ready', 'shipping']
  const forbiddenStates = [
    'delivered',
    'pending_sale',
    'pending_auction',
    'evaluation_bid',
    'bidding_finished',
    'evaluation_ready',
    'accepted',
    'loan_active',
    'return_pending',
    'on_hold',
    'pre_inventory',
    'inventory',
    'sale_scheduled',
    'done',
  ]

  const hasForbiddenStates = [
    ...new Set(items.filter((item: Item) => forbiddenStates.includes(item.state)).map((item: Item) => item.itemId)),
  ]

  // Fltering needed states
  const filteredItems = items.filter(
    (item: Item) => allowedStates.includes(item.state) && !hasForbiddenStates.includes(item.itemId),
  )

  // Filtering duplicates
  const uniqueItems = new Set(filteredItems.map((item: Item) => item.itemId))

  return uniqueItems.size
}

const emailRegex = /[^\s@]+@[^\s@]+\.[^\s@\.]{2,}/

export const isValidEmail = (email: string) => {
  return emailRegex.test(email)
}

export const prepUtmParams = (data: URLSearchParams) => {
  return {
    utmSource: data.get('utm_source'),
    utmMedium: data.get('utm_medium'),
    utmCampaign: data.get('utm_campaign'),
    utmTerm: data.get('utm_term'),
    utmContent: data.get('utm_content'),
    utmReferrer: data.get('utm_referrer'),
  }
}

export function sessionDataToSendAsQueryString(url: string) {
  const newUrl = new URL(url)

  // Get the URL's current query parameters.
  const params = new URLSearchParams(newUrl.search)

  //utm data
  const utmData = getUtmSessionData()
  if (utmData) {
    Object.keys(utmData).forEach((key) => {
      if (utmData[key]) params.set(key, utmData[key])
    })
  }

  //uvid - from tag manager
  const uvid = Cookies.get('uvid') || undefined
  if (uvid) params.set('uvid', uvid)

  //add referrer
  const referrer = document.referrer
  if (referrer) params.set('referrer', referrer)

  // Update the URL's query parameters.
  newUrl.search = params.toString()

  return newUrl.toString()
}
