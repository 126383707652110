const getSubDomainPrefix = () => window.location.host.split('.')[0]

const isReverseProxy = () => ['www', 'qa', 'demo', 'london', 'staging', 'rio'].includes(getSubDomainPrefix())

const getEnvName = () => {
  if (typeof window === 'undefined') return process.env.ENV
  const subDomainPrefix = getSubDomainPrefix()
  if (subDomainPrefix.includes('local')) return 'development'

  if (isReverseProxy()) {
    if (subDomainPrefix === 'www') return 'production'
    return subDomainPrefix
  }

  // if one of our online test environments
  if (subDomainPrefix.includes('-')) return subDomainPrefix.split('-')[1]
  return 'production'
}

export const envName = getEnvName()
export const isProduction = () => envName === 'production'

/**
 * Setting the environment keys only once. Once they're available they
 * will not change (this session)
 */
export const envUrls = {
  site: process.env.SITE_URL || 'https://www.worthy.com',
  app: process.env.APP_URL || 'https://app.worthy.com',
  authenticator: process.env.AUTHENTICATOR_URL || 'https://auth.worthy.com',
  contentBE: process.env.CONTENT_BE_URL || 'https://contentbe.worthy.com',
  submissionFlowReposition: process.env.SUBMISSION_FLOW_REPOSITION_URL || 'https://submit.worthy.com',
  marketplace: process.env.MARKETPLACE_URL || 'https://shop.worthy.com/',
  blog: process.env.BLOG_URL || 'https://blog.worthy.com',
  dataCollector: process.env.DATA_COLLECTOR_URL || 'https://collect.worthy.com',
}

export function replaceBuildTimeUrls(inUrl: string) {
  return inUrl
    .replace('#siteUrl#', envUrls.site)
    .replace('#appUrl#', envUrls.app)
    .replace('#marketplaceUrl#', envUrls.marketplace)
    .replace('#blogUrl#', envUrls.blog)
}
