import operations from '@worthy-npm/worthy-shared-content/dist/operations'

const worthyAddr = operations.worthyOfficeMain.address

export const INFO_WORTY_EMAIL = 'info@worthy.com'
export const WORTHY_PHONE = operations.worthyOfficeMain.phone
export const WORTHY_NY_ADDRESS =
  worthyAddr.street + ', ' + worthyAddr.floor + ', ' + worthyAddr.city + ', ' + worthyAddr.state + ' ' + worthyAddr.zip
export const WORTHY_NY_ADDRESS_WITHOUT_NY = worthyAddr.street + ', ' + worthyAddr.floor
export const WORTHY_BY_ADDRESS_FORMATTED =
  worthyAddr.street.replace(/ /g, '&nbsp;') +
  ', ' +
  worthyAddr.floor.replace(/ /g, '&nbsp;') +
  ', ' +
  worthyAddr.city.replace(/ /g, '&nbsp;') +
  ', ' +
  worthyAddr.state.replace(/ /g, '&nbsp;') +
  '&nbsp;' +
  worthyAddr.zip.replace(/ /g, '&nbsp;')
