/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash-es'
import { envUrls } from './objects/env'
import { sessionDataToSendAsQueryString } from '../utils/helpers'

const { REACT_APP_GIT_HASH } = process.env
declare global {
  interface Window {
    dataLayer: any
    google_tag_manager?: any
    location: Location
  }
}

export const EVENTS = {
  names: {
    interaction: 'interaction',
    data: 'pushData',
    feedback: 'feedback',
    experiment: 'experimentParticipation',
  },
  actions: {
    open: 'open',
    click: 'click',
    success: 'success',
    select: 'select',
    input: 'input',
    navigate: 'navigate',
    clear: 'clear',
    toggle: 'toggle',
    failure: 'failure',
    close: 'close',
  },
} as const

interface KeyVal {
  [key: string]: any
}

interface EventParams {
  event: (typeof EVENTS.names)[keyof typeof EVENTS.names]
  action: (typeof EVENTS.actions)[keyof typeof EVENTS.actions]
  location: string[]
  actionedObject?: string
  actionedValue?: any
  actionedObject2?: string
  actionedValue2?: any
  actionedObject3?: string
  actionedValue3?: any
  actionedObject4?: string
  actionedValue4?: any
  actionedObject5?: string
  actionedValue5?: any
  redirectTo?: string
  newTab?: boolean
  topic?: string
  fieldName?: string
  fieldValue?: number
}

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || []
}

const removeUndefined = (obj: KeyVal) => {
  // eslint-disable-next-line no-param-reassign
  Object.keys(obj).forEach((key) => ([null, undefined].includes(obj[key]) ? delete obj[key] : {}))
  return obj
}

export const callbackObj = (url?: string, newTab = false) => {
  let obj = {}
  let newUrl = url
  if (newUrl) {
    //check if its going to submission and if so, send query params
    if (newUrl.startsWith(envUrls.submissionFlowReposition)) {
      newUrl = sessionDataToSendAsQueryString(newUrl)
    }
    if (window?.google_tag_manager) {
      obj = {
        eventCallback() {
          if (newTab) {
            window.open(newUrl, '_blank')
          } else {
            window.location.href = newUrl || ''
          }
        },
        eventTimeout: 2000,
      }
    } else {
      console.log('redirect without event to:', newUrl)
      if (newTab) {
        window.open(newUrl, '_blank')
      } else {
        window.location.href = newUrl
      }
    }
  }
  return obj
}

export class BaseEvent {
  static userDataSent = false

  static pushToDataLayer = (data: Record<string, any>) => {
    if (isEmpty(data)) return
    window.dataLayer.push(data)
  }

  static locationObject = (location: string[]) => {
    return {
      location1: location[0] || null,
      location2: location[1] || null,
      location3: location[2] || null,
      location4: location[3] || null,
      location5: location[4] || null,
    }
  }

  static eventObject = (params: EventParams) => ({
    event: params.event,
    action: params.action || null,
    actionedObject: params.actionedObject || null,
    actionedValue: params.actionedValue || null,
    actionedObject2: params.actionedObject2 || null,
    actionedValue2: params.actionedValue2 || null,
    actionedObject3: params.actionedObject3 || null,
    actionedValue3: params.actionedValue3 || null,
    actionedObject4: params.actionedObject4 || null,
    actionedValue4: params.actionedValue4 || null,
    actionedObject5: params.actionedObject5 || null,
    actionedValue5: params.actionedValue5 || null,
    appVersion: REACT_APP_GIT_HASH || null,
    topic: params.topic || null,
    fieldName: params.fieldName || null,
    fieldValue: params.fieldValue || null,
    ...this.locationObject(params.location),
    ...(params.redirectTo ? callbackObj(params.redirectTo, params.newTab) : {}),
  })

  static dataEventObject = (data: any) =>
    removeUndefined({
      ...data,
      event: EVENTS.names.data,
      appVersion: REACT_APP_GIT_HASH,
    })

  static data(data: Record<string, unknown>) {
    this.pushToDataLayer(this.dataEventObject(data))
  }

  static open(params: Pick<EventParams, 'topic' | 'location'>) {
    this.pushToDataLayer(this.eventObject({ event: EVENTS.names.feedback, action: EVENTS.actions.open, ...params }))
  }

  static click(params: Pick<EventParams, 'topic' | 'location' | 'actionedObject' | 'actionedValue'>) {
    this.pushToDataLayer(
      this.eventObject({
        event: EVENTS.names.interaction,
        action: EVENTS.actions.click,
        ...params,
      }),
    )
  }

  static success(params: Pick<EventParams, 'topic' | 'location' | 'actionedObject' | 'fieldName' | 'fieldValue'>) {
    this.pushToDataLayer(this.eventObject({ event: EVENTS.names.feedback, action: EVENTS.actions.success, ...params }))
  }

  static select(
    params: Pick<
      EventParams,
      'topic' | 'location' | 'actionedObject' | 'actionedValue' | 'redirectTo' | 'actionedObject2' | 'actionedValue2'
    >,
  ) {
    this.pushToDataLayer(
      this.eventObject({
        event: EVENTS.names.interaction,
        action: EVENTS.actions.select,
        ...params,
      }),
    )
  }

  static navigate(
    params: Pick<
      EventParams,
      | 'topic'
      | 'location'
      | 'actionedObject'
      | 'actionedValue'
      | 'actionedObject2'
      | 'actionedValue2'
      | 'redirectTo'
      | 'newTab'
    >,
  ) {
    this.pushToDataLayer(
      this.eventObject({ event: EVENTS.names.interaction, action: EVENTS.actions.navigate, ...params }),
    )
  }

  static input(params: Pick<EventParams, 'topic' | 'location' | 'actionedObject' | 'actionedValue'>) {
    this.pushToDataLayer(
      this.eventObject({
        event: EVENTS.names.interaction,
        action: EVENTS.actions.input,
        ...params,
      }),
    )
  }

  static experiment({ experimentName, variantName }: { experimentName: string; variantName: string }) {
    this.pushToDataLayer({
      event: EVENTS.names.experiment,
      experimentName,
      variantName,
    })
  }
}
