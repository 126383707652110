import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import userReducer from './userSlice'
import { UserApi } from './ApiSlices/userApiSlice'
import { RecentDealsApi } from './ApiSlices/recentDealsApiSlice'

const createStore = () =>
  configureStore({
    reducer: {
      user: userReducer,
      [UserApi.reducerPath]: UserApi.reducer,
      [RecentDealsApi.reducerPath]: RecentDealsApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(UserApi.middleware, RecentDealsApi.middleware),
  })

const store = createStore()
setupListeners(store.dispatch)

type ConfiguredStore = ReturnType<typeof createStore>
type StoreGetState = ConfiguredStore['getState']
export type RootState = ReturnType<StoreGetState>
export type AppDispatch = ConfiguredStore['dispatch']

export default store
