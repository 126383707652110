// gatsby-browser.js and gatsby-ssr.js
import React from 'react'
import { Provider } from 'react-redux'
import store from './src/store'
import { setUtmSessionData } from './src/data/utmData'

export const wrapRootElement = ({ element }) => {
  setUtmSessionData()
  return <Provider store={store}>{element}</Provider>
}
