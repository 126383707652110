import axios from 'axios'
import { envUrls } from '../objects/env'
import Cookies from 'js-cookie'

export const INTERACTIONS = {
  HP_CTA_CLICK: {
    name: 'HP_CTA_CLICK',
    values: {
      HEADER: 'HEADER',
      HERO: 'HERO',
      SELECTION_POPUP: 'SELECTION_POPUP',
      HIW_SECTION: 'HIW_SECTION',
      STICKY_BOTOM: 'STICKY_BOTTOM',
    },
  },
} as const
export type InteractionNames = keyof typeof INTERACTIONS
export type InteractionValues = (typeof INTERACTIONS.HP_CTA_CLICK.values)[keyof typeof INTERACTIONS.HP_CTA_CLICK.values]

function contentBEUrl(path: string) {
  return `${envUrls.contentBE}/${path}`
}

export class ContentBEAPI {
  static async updateSessionExperiment({
    experimentName,
    variantName,
  }: {
    experimentName: string
    variantName: string
  }) {
    const url = contentBEUrl(`sessionUpdate`)
    const uvid = Cookies.get('uvid')
    if (!uvid) {
      //need to be logged to BE
      console.log('No uvid found in cookies')
      return
    }
    try {
      await axios.post(
        url,
        {
          uvid,
          experiments: {
            [experimentName]: variantName,
          },
        },
        { withCredentials: true },
      )
    } catch (err) {
      // need to be stored to BE
      console.log(
        'Error in sending experiments to conetntBE',
        {
          experimentName,
          variantName,
          uvid,
          url: window.location.href,
        },
        err,
      )
    }
  }

  static async updateSessionInteractions({
    interactionName,
    interactionValue,
  }: {
    interactionName: InteractionNames
    interactionValue: InteractionValues
  }) {
    const url = contentBEUrl(`sessionUpdate`)
    const uvid = Cookies.get('uvid')
    if (!uvid) {
      //need to be logged to BE
      console.log('No uvid found in cookies')
      return
    }
    try {
      await axios.post(
        url,
        {
          uvid,
          interactions: {
            [interactionName]: interactionValue,
          },
        },
        { withCredentials: true },
      )
    } catch (err) {
      // need to be stored to BE
      console.log(
        'Error in sending experiments to conetntBE',
        {
          interactionName,
          interactionValue,
          uvid,
          url: window.location.href,
        },
        err,
      )
    }
  }
}
